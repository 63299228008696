.videoContainer {
  position: absolute;
  z-index: 0;
}

.video {
  width: 100vw !important;
  /* height: 100vh !important; */

}

.overlay {
  height: 100vh;
}

:root {
  --title: 130px;
  --subTitle: 80px;
}

.Title {
  font-family: "Hurricane", cursive;
  position: relative;
  z-index: 2;
}

@media (max-width: 600px) {
  :root {
    --title: 70px;
    --subTitle: 40px;
  }

  .overlay {
    height: 60vh;
  }
  .video {
    height: 60vh !important;
  }
}

.fade-in {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  /* Dim background */
  z-index: 10;
  /* Above iframe */
}

.masonry-grid {
  display: flex;
  margin-left: -15px;
  /* Gutter size */
  width: auto;
}

.masonry-grid_column {
  padding-left: 30px;
  /* Gutter size */
  background-clip: padding-box;
}

.masonry-item {
  margin-bottom: 30px;
  /* Vertical spacing */
  position: relative;
  /* To position spinner */
}

.masonry-item iframe {
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 10px;
  /* Optional */
}


.nav-link.active {
  background-color: transparent !important;
  color: orange !important;
  border: none !important;
  border-bottom: 1px solid orange !important;

}

.nav-link {
  background-color: none !important;
  border: none !important;
  color: white !important;
}

.nav-link:hover {
  border: none !important;
  border-bottom: 1px solid orange !important;
}